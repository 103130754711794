import HomeOutlined from '@ant-design/icons/HomeOutlined';
import allPaths from '../Config/paths';
import global from '../Config/global';

let Chromatic3DProds = [];
const bgColor = `#0adc00`;
const storLocModalId = '63b4cc4f65be5d2b79774084';
const albaRacingModelId = '660239aaef09dc00151085db';
const SkyHookModelID = '66dfc8b71c6a220048e07e93';
const LIBERTY_PEN_GUN = '66a140c95b966d0015b9c74c';
const ErnestModelIds = ['663c5ed91969a3312eeb96b5'];
const CONFIGURATIONINPUTTYPES = {
  sliderRange: 1826,
  checkBox: 2550,
  dropDown: 105,
  textController: 872
};

const DondeProductsID = [
  "671fd41af0a2400015e9a043", // DondeFoot
  "6747b05610927100154bc2fb", // The Donde Foot
  "674620618b045d0015d80f8b", // DondeFoot Test 2
]

let mezcoModelIds = [];
// const mezcoModelIds= [
//   '6626c5aa5756571fc16015ec',
//   '66275b535756571fc16015f0',
//   '66276ff45756571fc16015f3',
//   '662797135756571fc16015f9',
//   '6627987b5756571fc16015fd'
// ];
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  mezcoModelIds = [
    '65fc9b784c3e5f001596b34c', // Baseplate
    '65fc9b484c3e5f001596b346',
    '65fc9b024c3e5f001596b33e',
    '65fc9ac24c3e5f001596b339',
    '65fc9a6c4c3e5f001596b333',
    //Dev Brackets
    '66d954e217d8ccc0ec76fc2f', // Bracket L
    '66d9ae9517d8ccc0ec76fc34', // Bracket C
    '66d9af5217d8ccc0ec76fc35', // Bracket Z
    '66db9f02978981003ceb92d0', // Too Tall Toby
    '66fb4f6aff52d700357cbb07', // Halloween Lantern
    '671fe5622f129e00152cd356' // Christmas lantern
  ];
} else {
  mezcoModelIds = [
    '6626c5aa5756571fc16015ec', //Baseplate
    '66275b535756571fc16015f0',
    '66276ff45756571fc16015f3',
    '662797135756571fc16015f9',
    '6627987b5756571fc16015fd',
    //Dev Brackets
    '66d954e217d8ccc0ec76fc2f', // Bracket L
    '66d9ae9517d8ccc0ec76fc34', // Bracket C
    '66d9af5217d8ccc0ec76fc35', // Bracket Z
    '66db9f02978981003ceb92d0', // Too Tall Toby
    '66fb4f6aff52d700357cbb07', //Halloween Lantern
    '671fe5622f129e00152cd356' // Christmas lantern
  ];
}
const drawerRoutes = [
  {
    title: `Home`,
    route: allPaths.ADMIN,
    icon: <HomeOutlined />
  }
];

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Chromatic3DProds = [
    '6446b009c5b63632f3c1cb15',
    '6446b009c5b63632f3c1cb18',
    '6446b009c5b63632f3c1cb1b',
    '6446b009c5b63632f3c1cb1e',
    '6446b009c5b63632f3c1cb21',
    '6446b009c5b63632f3c1cb24'
  ];
} else {
  Chromatic3DProds = [
    '64424b7dca971f39d4e31e44',
    '64424b7dca971f39d4e31e47',
    '64424b7dca971f39d4e31e4a',
    '64424b7dca971f39d4e31e4d',
    '64424b7dca971f39d4e31e50',
    '64424b7dca971f39d4e31e53'
  ];
}

let SellerID;
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  SellerID = {
    mezcoID : "6627a6505626ec89e53213ad"
  };
} else {
  SellerID = {
    mezcoID : "66241b74fc102565264019cc"
  };
}

const adminTypes = {
  sub: 'SUB',
  super: 'SUPER'
};

const EARNESTMAXVALUESDEPS = {
  ThruDiameter: 'Diameter',
  WireDiameter: 'Diameter',
  PinDiameter: 'Diameter',
  DogPointDiameter: 'Diameter',
  DogPointLength: 'Length',
  PartialThread: 'Length'
};

const EARNESTCHECKDEPS = {
  ThruDiameter: 'Post_Processing__Top_hole',
  WireDiameter: 'Post_Processing__Side_hole',
  PinDiameter: 'Pin_Lock_Hole',
  PinOffset: 'Pin_Lock_Hole',
  DogPointDiameter: 'Dog_Point',
  DogPointLength: 'Dog_Point',
  PartialThread: 'List_QAoAroAoJ5JICg'
};

const OnShapeDocuments = [
  {
    name: "Mezco Fab",
    documentsID : '41f84608ba03c854585ea8a0',
    workspaceID : '3eb6bb889a26f983476585d1'
  },
  {
    name: "Earnest Custom Feature Gen",
    documentsID : 'b3b81d4b6a30384e198c7c7e',
    workspaceID : 'ead1cfcb18078841ca8bd810'
  },
]

export {
  bgColor,
  storLocModalId,
  albaRacingModelId,
  mezcoModelIds,
  drawerRoutes,
  allPaths,
  Chromatic3DProds,
  adminTypes,
  global,
  EARNESTMAXVALUESDEPS,
  EARNESTCHECKDEPS,
  LIBERTY_PEN_GUN,
  ErnestModelIds,
  SkyHookModelID,
  CONFIGURATIONINPUTTYPES,
  SellerID,
  OnShapeDocuments,
  DondeProductsID
};
