import React, { useEffect, useState } from 'react';
// import { getConfigParamsSchema } from '../../utils/helpers';
import { InputLabel } from '@mui/material';
import Select from 'react-select';
// import { Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const LookUpTable = (props) => {
  const { lookupJSON, parameterSchema, setParameterSchema, watch, control, setValue, modelData, cachedConfiguration } = props;
  const [defaultLoaded, setDefaultLoaded] = useState(false);
  const [tempVal, setTempVal] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (modelData?.hasLookupfields && defaultLoaded == false && parameterSchema.length > 0) {
      let deafaultConfigurationInputs = JSON.parse(modelData?.featureScriptConf?.configurationParameters[0]?.message?.defaultValue);
      if (cachedConfiguration && cachedConfiguration?.configuration && cachedConfiguration?.configuration?.length > 0) {
        const tempCached = cachedConfiguration?.configuration.find((param) => {
          return param.identifier === modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
        });

        deafaultConfigurationInputs = JSON.parse(tempCached?.value);
      }
      if (modelData?.modelId === '663c5ed91969a3312eeb96b5') {
        setValue('Standard', 'ISO');
      }
      let optChain = '';
      let prevDefaultVal = '';
      let lastOpts = {};
      let tmp = {};
      parameterSchema.forEach((param, index) => {
        let defaultVal = deafaultConfigurationInputs[param?.name];

        if (modelData?.modelId === '663c5ed91969a3312eeb96b5') {
          defaultVal = index === 0 ? 'ISO' : defaultVal;
          if (param?.name == 'flange') {
            defaultVal = `${defaultVal}`;
          }
          lastOpts = index > 0 ? lastOpts[prevDefaultVal]['options'] : lookupJSON.options;
          if (index + 1 == parameterSchema.length) {
            const basePrice = lastOpts[defaultVal]['basePrice'];
            setValue('BasePrice', basePrice);
            // console.log('lastOpt', lastOpts, defaultVal, basePrice);
          }
          prevDefaultVal = defaultVal;
          optChain = optChain === '' ? `${param?.name}` : `${optChain}.${param?.name}`;
        }
        setValue(param?.name, defaultVal);
        tmp[param.name] = defaultVal;
      });
      setTempVal(tmp);
      setDefaultLoaded(true);
    }
  }, [modelData, parameterSchema, defaultLoaded]);

  const handleLookupChange = (optChain, paramName, paramValue, level) => {
    if (paramValue?.value === tempVal[paramName]) {
      return;
    }
    // const val = paramValue?.value;
    // setValue(paramName, val);

    let tmpValNew = { ...tempVal };

    setParameterSchema((prevSchema) => {
      const tt = prevSchema.map((param, index) => {
        if (level == index) {
          tmpValNew[param.name] = paramValue?.value;
        } else if (index > level) {
          tmpValNew[param.name] = null;
        }
        return {
          ...param,
          disabled: index > level + 1
        };
      });
      return tt;
    });
    setTempVal(tmpValNew);
    // console.log("file: LookUpTable.jsx:98 ~ handleLookupChange ~ tmpValNew:", tmpValNew);

    if (level + 1 == parameterSchema.length) {
      Object.keys(tmpValNew).map((paramNm) => {
        setValue(paramNm, tmpValNew[paramNm]);
      });
      if (modelData?.modelId === '663c5ed91969a3312eeb96b5') {
        if (level + 1 == parameterSchema.length) {
          const optArr = extractOPTArr(optChain, level);
          const basePrice = optArr[paramValue?.value]['basePrice'];
          setValue('BasePrice', basePrice);
        }
      }
    } else {
      enqueueSnackbar('Please select a configuration option to view pricing!', { variant: 'warning' });
    }
  };



  const extractOPTArr = (optChain, level) => {
    const optChainArr = optChain.split('.');
    let optArr = lookupJSON.options;
    for (let i = 1; i <= level; i++) {
      if (!optArr[tempVal[optChainArr[i - 1]]]?.options) return [];
      optArr = optArr[tempVal[optChainArr[i - 1]]].options;
    }
    return optArr;
  };

  const getOptions = (optChain, level) => {
    if (!lookupJSON || !lookupJSON.options) return [];
    if (level === 0) {
      return Object.keys(lookupJSON.options).map((opt) => ({
        label: lookupJSON.options[opt]?.optLabel || 'Unknown',
        value: opt
      }));
    } else {
      const optArr = extractOPTArr(optChain, level);
      return Object.keys(optArr).map((opt) => ({
        label: optArr[opt]?.optLabel || 'Unknown',
        value: opt
      }));
    }
  };

  return (
    <>
      {parameterSchema &&
        parameterSchema.map((param, i) => {
          const opts = !param.disabled ? getOptions(param.optChain, i) : [];
          return (
            <React.Fragment key={`lookupparam-${i}`}>
              <InputLabel>{param.label}</InputLabel>
              {/* <Controller
                name={param?.name}
                control={control}
                render={(props) => ( */}
              <Select
                className="mezco-material-selector"
                isClearable={false}
                isSearchable={true}
                // value={watch(param.name) ? opts.find((opt) => opt.value == watch(param.name)) : null}
                value={tempVal[param.name] ? opts.find((opt) => opt.value == tempVal[param.name]) : null}
                options={opts}
                onChange={(value) => handleLookupChange(param.optChain, param.name, value, i)}
                isDisabled={param.disabled}
                styles={{
                  menu: (provided) => ({ ...provided, marginBottom: 0, marginTop: 0 }),
                  menuList: (provided) => ({ ...provided, paddingTop: 0, paddingBottom: 0 })
                }}
              />
              {/* )}
              /> */}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default LookUpTable;
