import React from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';

const PriceBreakdown = ({breakDownValues, Quantity}) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h6" sx={{ color: theme.palette.primary.dark }}>Price Breakdown</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><b>Base Price (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${breakDownValues?.base}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Hole Machining (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${breakDownValues?.holes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Dogpoint Machining (Per Unit)</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${breakDownValues?.dogPoint}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Subtotal (Pre-Markup)</b></TableCell>
              <TableCell>Base + Holes + Dogpoint</TableCell>
              <TableCell>${breakDownValues?.base + breakDownValues?.holes + breakDownValues?.dogPoint}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Markup</b></TableCell>
              <TableCell>% x Subtotal</TableCell>
              <TableCell>${breakDownValues?.Markup}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Total Unit Price</b></TableCell>
              <TableCell>Subtotal + Markup</TableCell>
              <TableCell>${(breakDownValues?.base + breakDownValues?.holes + breakDownValues?.dogPoint) + (((breakDownValues?.base + breakDownValues?.holes + breakDownValues?.dogPoint)*breakDownValues?.Markup)/100)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Quantity</b></TableCell>
              <TableCell></TableCell>
              <TableCell>${Quantity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Extended Cost</b></TableCell>
              <TableCell>Total Unit Price + Quantity</TableCell>
              <TableCell>${((breakDownValues?.base + breakDownValues?.holes + breakDownValues?.dogPoint) + (((breakDownValues?.base + breakDownValues?.holes + breakDownValues?.dogPoint)*breakDownValues?.Markup)/100) * Quantity)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Setup Fee</b></TableCell>
              <TableCell>(one-time)</TableCell>
              <TableCell>${breakDownValues?.setup}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Final Total</b></TableCell>
              <TableCell>Extended Cost + Setup Fee</TableCell>
              <TableCell>${breakDownValues["Total Price"]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'none',
            md: '50% 1fr'
          },
          color: '#000'
        }}
      > 
        <Box>
          <label className="boundingBoxLabel">Base Price:</label> ${breakDownValues?.base}
        </Box>
        <Box>
          <label className="boundingBoxLabel">DogPoint Price:</label> ${breakDownValues?.dogPoint}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Holes Price:</label> ${breakDownValues?.holes}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Markup Price:</label> ${breakDownValues?.Markup}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Unit Price:</label> ${breakDownValues?.price}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Quantity:</label> {Quantity}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Setup Charge:</label> ${breakDownValues?.setup}
        </Box>
        <Box>
          <label className="boundingBoxLabel">Total Price:</label> ${breakDownValues["Total Price"]}
        </Box>
      </Box> */}
    </Box>
  )
}

export default PriceBreakdown;