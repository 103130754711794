import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { requiredMessage } from '../../utils/helpers';
import { Input as MuiInput, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ErrorMessage, FormControlBox } from '../../Components/Common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { USER } from '../../utils/apis';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../Redux/actions/authActions';
import { allPaths } from '../../utils/constants';
import { loginAdmin } from '../../Redux/actions/adminAuthActions';
import { removeCustomer } from '../../Redux/actions/customerAuthActions';

const validatonSchema = Yup.object().shape({
  email: Yup.string().nullable().trim().required(requiredMessage('Email')).email('Please enter valid email address!'),
  password: Yup.string().required(requiredMessage('Password'))
});

const Login = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
  const user = useSelector((state) => state.adminAuthReducer?.admin);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validatonSchema),
    defaultValues: {
      email: '',
      password: '',
      userRole: 3,
      allowSeller: 1
    }
  });

  useEffect(() => {
    if (props?.isPrivateStorefront == false) {
      if (user && user?.userRole == '3') {
        navigate(allPaths.MY_PROFILE);
      }
    }
  }, [user]);

  const handleClickShowPassword = (field) => {
    let showPasswordState = { ...showPassword, [field]: !showPassword[field] };
    setShowPassword(showPasswordState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    axios
      .post(USER.POST.LOGIN, data)
      .then((res) => {
        if (res.status === 200) {
          const { user } = res.data;
          axios.defaults.headers.common['Authorization'] = `Bearer ${user?.accessToken}`;

          enqueueSnackbar('Login Successful', { variant: 'success' });
          dispatch(removeCustomer())
          dispatch(loginAdmin(user));
          if (props?.isPrivateStorefront) {
            setTimeout(() => {
              navigate(0);
            }, 500)
          } else if (state && state?.redirectTo) {
            window.location.href = state?.redirectTo;
          } else {
            navigate(allPaths.MY_PROFILE);
          }
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
          reset({
            email: '',
            password: ''
          });
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res?.data?.message) {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      });
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          margin: '0 auto',
          pt: '40px'
        }}
      >
        <Typography variant="h4">Login</Typography>
        {props?.isPrivateStorefront && props?.isPrivateStorefront == true && (
          <Typography>
            {props?.modelName} is reserved for members of {props?.sellerStoreName}. Please log in to gain access.
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            my: '40px'
          }}
        >
          <FormControlBox>
            <FormLabel>Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <MuiInput
                  value={field?.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Your Email"
                  error={errors?.email ? true : false}
                />
              )}
            />
            {errors.email && <ErrorMessage msg={errors?.email?.message} />}
          </FormControlBox>
          <FormControlBox>
            <FormLabel>Password</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <MuiInput
                  value={field?.value}
                  onChange={(value) => field.onChange(value)}
                  type={showPassword?.password ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('password')}
                        onMouseDown={() => handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword?.password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Your Password"
                  error={errors?.password ? true : false}
                />
              )}
            />
            {errors.password && <ErrorMessage msg={errors?.password?.message} />}
          </FormControlBox>
          <FormControlBox
            sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: '80%', alignItems: 'baseline' }}
          >
            <Button type="submit" variant="contained">
              Login
            </Button>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                mt: '10px'
              }}
            >
              {/* {props?.isPrivateStorefront && props?.isPrivateStorefront == true ? (
                <Box></Box>
              ) : (
                <Typography>
                  {`Don't have account? `}
                  <Link to="/register">Signup</Link>
                </Typography>
              )} */}
              <Typography>
                <Link to="/forgot-password">Forgot Password</Link>
              </Typography>
              <Typography></Typography>
            </Stack>
          </FormControlBox>
        </Box>
      </Container>
    </>
  );
};

export default Login;
